<template>
  <div class="create-or-edit">
    <div>
      <el-form :model="form" label-position="left" label-width="80px" size="small">
        <el-form-item label="快递单号" >
          <el-input v-model="form.mailNo" disabled></el-input>
        </el-form-item>
        <el-form-item label="拦截状态">
          <el-select v-model="form.waylayStatus" placeholder="请选择拦截状态" clearable>
            <el-option v-for="item in waylayStatusList" :key="item.status" :label="item.statusDesc" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拦截归类">
          <el-select v-model="form.waylaySortOut" placeholder="请选择拦截归类" clearable>
            <el-option v-for="item in waylaySortOutList" :key="item.status" :label="item.statusDesc" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拦截描述">
          <el-input type="textarea" placeholder="请输入拦截原因，不超过200字" v-model="form.tackDesc"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onsubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {getUserInfo} from '@/services/user'
import { waylayTack } from '@/services/logistics'
import {findAllLaywayStatus,findAllWaylaySortOut} from '@/services/status'
export default {
  name: 'WaylayTack',
  props: {
    waylayStatus: {
      type: [String]
    },
    waylaySortOut: {
      type: [String]
    },
    mailNo: {
      type: [String]
    }
  },
  created () {
    this.loadWaylaySortOut()
    this.loadLaywayStatus()
    this.loadUserInfo()
    this.form.waylayStatus = this.waylayStatus
    this.form.mailNo = this.mailNo
    this.form.waylaySortOut = this.waylaySortOut
  },
  data () {
    return {
      userInfo: {},
      form: {
        mailNo: '',
        tackDesc: '',
        tackName: '',
        tackPhone: '',
        waylayStatus: '',
        waylaySortOut:'',
      },
      waylayStatusList: [],
      waylaySortOutList: [],
    }
  },
  methods: {
    onCancel () {
      //设置取消状态，让父组件处理
      this.$emit('cancel')
      this.form = {}
    },
    async onsubmit () {
      this.form.tackName=this.userInfo.userName
      this.form.tackPhone=this.userInfo.phone
      const { data } = await waylayTack(this.form)
      if (200 === data.code) {
        // 关闭提示框(需要子组件向父组件传递状态)
        this.$emit('success')
        // 设置成功提示
        this.$message.success('添加成功')
        // 清除表单内容
        this.form = {}
      } else {
        this.$message.error(data.message)
      }
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
    async loadLaywayStatus() {
      // 查询拦截状态列表
      const {data} = await findAllLaywayStatus()
      if (200 === data.code) {
        this.waylayStatusList = data.data
      } else {
        this.$message.error('查询拦截状态列表失败')
      }
    },
    async loadWaylaySortOut() {
      // 查询拦截归类列表
      const {data} = await findAllWaylaySortOut()
      if (200 === data.code) {
        this.waylaySortOutList = data.data
      } else {
        this.$message.error('查询拦截归类列表失败')
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
}
</style>
