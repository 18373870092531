<template>
  <div class="waylay">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom:20px;">
          快递拦截信息
        </div>
      </div>
      <!--      显示添加角色对话框-->

      <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="small">
        <el-form-item label="快递单号" prop="mailNo">
          <el-input v-model="form.mailNo" clearable size="small"></el-input>
        </el-form-item>
<!--        <el-form-item label="部门归属：" prop="deptSortOut">-->
<!--          <el-select v-model="form.deptSortOut" placeholder="全部" clearable>-->
<!--            <el-option v-for="item in deptSortOutList" :key="item.status" :label="item.statusDesc"-->
<!--                       :value="item.status"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="拦截录入时间：">
          <el-date-picker
            v-model="form.waylayDateQuery"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="创建人：" prop="createdBy">
          <el-select v-model="form.createdBy" placeholder="全部" clearable>
            <el-option v-for="(item,i) in createdByList" :key="i" :label="item"
                       :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拦截状态：" prop="waylayStatus">
          <el-select v-model="form.waylayStatus" placeholder="全部" clearable>
            <el-option v-for="item in waylayStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拦截归类">
          <el-select v-model="form.waylaySortOut" placeholder="全部" clearable>
            <el-option v-for="item in waylaySortOutList" :key="item.status" :label="item.statusDesc" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini" :disabled="isLoading">查询</el-button>
        </el-form-item>
        <el-form-item class="btn-right">
          <el-button @click="showDialogWaylayInit()" size="mini" type="danger">添加拦截</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="small">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="deptSortOut" label="部门归属">
            <template slot-scope="scope">
              <span>{{ scope.row.deptSortOut | deptSortOutFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createdBy" label="创建人"></el-table-column>
          <el-table-column prop="mailNo" label="快递单号"></el-table-column>
          <!-- 设置过滤器需要使用作用域插槽获取数据-->
          <el-table-column label="拦截录入时间">
            <template slot-scope="scope">
              <span>{{ scope.row.waylayTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column  label="拦截状态">
            <template slot-scope="scope">
              <span>{{ scope.row.waylayStatus | waylayStatusFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="拦截归类">
            <template slot-scope="scope">
              <span>{{ scope.row.waylaySortOut | waylaySortOutFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="260" fixed="right">
            <template slot-scope="{row}">
              <el-button @click="tackList(row)" type="success" size="mini">跟进记录</el-button>
              <el-button @click="tackCreat(row)" type="warning" size="mini" v-if="'2'!==row.waylayStatus">跟进</el-button>
              <el-button @click="del(row)" type="danger" size="mini" v-if="'2'!==row.waylayStatus">取消拦截</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10,50,100,200]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

      <!--  添加拦截弹窗-->
      <div v-if="dialogVisibleWaylayInit">
        <el-dialog
          title="添加拦截"
          :visible.sync="dialogVisibleWaylayInit"
          width="60%"
          :before-close="handleCancelWaylayInit">
          <!--将添加与编辑功能单独封装到组件中-->
          <create-waylay  @cancel="handleCancelWaylayInit" @success="handleSuccessWaylayInit"></create-waylay>
        </el-dialog>
      </div>

      <!--  跟进弹窗-->
      <div v-if="dialogVisibleTack">
        <el-dialog
          title="添加跟进"
          :visible.sync="dialogVisibleTack"
          width="60%"
          :before-close="handleCancelTack">
          <!--将添加与编辑功能单独封装到组件中-->
          <waylay-tack :waylay-status="waylayStatus" :waylay-sort-out="waylaySortOut" :mail-no="mailNo" @cancel="handleCancelTack" @success="handleSuccessTack"></waylay-tack>
        </el-dialog>
      </div>

      <!--  跟进记录弹窗-->
      <div v-if="dialogVisibleTackList">
        <el-dialog
          title="跟进记录"
          :visible.sync="dialogVisibleTackList"
          width="60%"
          :before-close="handleCancelTackList">
          <!--将添加与编辑功能单独封装到组件中-->
          <waylay-tack-list  :mail-no="mailNo" @cancel="handleCancelTackList" ></waylay-tack-list>
        </el-dialog>
      </div>

    </el-card>

  </div>
</template>

<script>
import {dateFormate} from '@/utils/dateUtil'
import {findAllLaywayStatus,findAllWaylaySortOut,findAllDeptSortOut} from '@/services/status'
import {findAllWaylayInfoByPage,findWaylayCreatedByList,delWaylayInfo} from '@/services/logistics'
import CreateWaylay from './components/CreateWaylay'
import WaylayTack from "./components/WaylayTack";
import {waylayStatusToCn,waylaySortOutToCn} from '@/utils/enums'
import WaylayTackList from "./components/WaylayTackList";
import {deptSortOutToCn} from "@/utils/enums";
import {getUserInfo} from '@/services/user'

export default {
  name: 'waylay',
  components: {WaylayTackList, WaylayTack, CreateWaylay },
  data() {
    return {
      userInfo: {},
      tableData: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      deptSortOutList: [],
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      // 用于存储拦截状态列表
      waylayStatusList: [],
      waylaySortOutList: [],
      createdByList: [],
      // 显示或隐藏对话框
      dialogVisibleWaylayInit: false,

      // 显示或隐藏对话框
      dialogVisibleTack: false,

      dialogVisibleTackList: false,
      //控制对话框的功能状态
      waylaySortOut: null,
      // 存储正在编辑的角色ID
      waylayStatus: null,
      mailNo: null,
    }
  },
  methods: {
    // 监听子组件的添加状态，成功时触发
    handleSuccessWaylayInit () {
      // 隐藏对话框
      this.dialogVisibleWaylayInit = false
      // 刷新列表
      this.loadAllWaylayInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelWaylayInit () {
      // 隐藏对话框
      this.dialogVisibleWaylayInit = false
    },

    // 监听子组件的添加状态，成功时触发
    handleSuccessTack () {
      // 隐藏对话框
      this.dialogVisibleTack = false
      // 刷新列表
      this.loadAllWaylayInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelTack () {
      // 隐藏对话框
      this.dialogVisibleTack = false
      this.waylayStatus = null
      this.waylaySortOut = null
      this.mailNo = null
    },

    // 监听子组件的取消状态
    handleCancelTackList() {
      // 隐藏对话框
      this.dialogVisibleTackList = false
      this.mailNo = null
    },

    //
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadAllWaylayInfoByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadAllWaylayInfoByPage()
    },

    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      await this.loadAllWaylayInfoByPage()
    },

    async loadLaywayStatus() {
      // 查询拦截状态列表
      const {data} = await findAllLaywayStatus()
      if (200 === data.code) {
        this.waylayStatusList = data.data
      } else {
        this.$message.error('查询拦截状态列表失败')
      }
    },
    async loadWaylaySortOut() {
      // 查询拦截归类列表
      const {data} = await findAllWaylaySortOut()
      if (200 === data.code) {
        this.waylaySortOutList = data.data
      } else {
        this.$message.error('查询拦截归类列表失败')
      }
    },
    async loadAllWaylayInfoByPage() {
      // 开始加载数据
      this.isLoading = true
      await this.loadUserInfo()
      this.form.deptSortOut = this.userInfo.company
      if (this.form && this.form.waylayDateQuery) {
        let waylayDateBegin = this.form.waylayDateQuery[0]
        let waylayDateEnd = this.form.waylayDateQuery[1]
        this.form.waylayDateBegin = waylayDateBegin
        this.form.waylayDateEnd = waylayDateEnd
      }
      // 分页多条件查询快递单号信息
      const {data} = await findAllWaylayInfoByPage(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },
    // 显示对话框
    showDialogWaylayInit() {
      this.dialogVisibleWaylayInit = true
    },

    // 显示跟进对话框
    tackCreat(row) {
      this.waylayStatus = row.waylayStatus
      this.waylaySortOut = row.waylaySortOut
      this.mailNo = row.mailNo
      this.dialogVisibleTack = true
    },
     // 显示跟进记录对话框
    tackList(row) {
      this.mailNo = row.mailNo
      this.dialogVisibleTackList = true
    },

    // 取消
    async del(row) {
      try {
        const params = {
          mailNo:row.mailNo,
          tackDesc:'取消拦截',
          tackName: this.userInfo.userName,
          tackPhone: this.userInfo.phone
        }
        await this.$confirm(`确定删除该拦截单号：${row.mailNo}吗？`, '删除提示')
        const { data } = await delWaylayInfo(params)
        if (200 === data.code) {
          this.$message.success('操作成功')
          await this.loadAllWaylayInfoByPage()
        } else {
          this.$message.error(data.message)
        }
      } catch (err) {
        if ('cancel' === err) {
          return this.$message.info('取消操作')
        }
      }
    },

    async loadDeptSortOut() {
      //查询部门归属列表
      const {data} = await findAllDeptSortOut()
      if (200 === data.code) {
        this.deptSortOutList = data.data
      } else {
        this.$message.error('查询部门归属列表失败')
      }
    },

    async loadWaylayCreatedByList() {
      // 查询快递状态列表
      const {data} = await findWaylayCreatedByList()
      if (200 === data.code) {
        this.createdByList = data.data
      } else {
        this.$message.error('列表失败')
      }
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },

  },
  created() {
    this.loadUserInfo()
    if (this.$route.query&&this.$route.query.waylayStatus) {
      this.form.waylayStatus = this.$route.query.waylayStatus
    }
    this.loadWaylayCreatedByList()
    this.loadDeptSortOut()
    this.loadLaywayStatus()
    this.loadWaylaySortOut()
    this.loadAllWaylayInfoByPage()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },
    // 拦截状态过滤器
    waylayStatusFormate(code) {
      return waylayStatusToCn(code)
    },

    // 拦截状态过滤器
    waylaySortOutFormate(code) {
      return waylaySortOutToCn(code)
    },

    deptSortOutFormate(code) {
      return deptSortOutToCn(code)
    },

  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}

.btn-right {
  float: right;
}

.el-table {
  margin-top: 10px;
}

.btn-div {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
